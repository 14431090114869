import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { Platform, NavController, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

import { OneSignal } from '@ionic-native/onesignal/ngx';
// import { AppVersion } from '@ionic-native/app-version/ngx';
// import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

declare global {
    interface Date {
        addDays (quantity?: number) : Date,
        addSeconds (quantity?: number) : Date,
    }
}

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addSeconds = function(seconds) {
    this.setSeconds(this.getSeconds() + seconds);
    return this;
};

@Injectable({
  providedIn: 'root'
})
export class CocoRESTService {

  constructor(
    private http: HttpClient,
    // private appVersion: AppVersion,
    // private firebaseAnalytics: FirebaseAnalytics,
    private platform: Platform,
    private toastController: ToastController,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private oneSignal: OneSignal,
    public navController: NavController,
    public router: Router
    ) {
    if(platform.is('cordova')) {
      // this.app_version = 'v.' + appVersion.getVersionNumber();
    }
  }

  private subject = new Subject<any>();

  // Sync-time
  public timediff = 0;
  syncTime(date) { if(date.addSeconds) date.addSeconds(this.timediff); return date; }

  sendMessage(text: string, data = {}) {this.subject.next({ text: text, data: data });}
  clearMessages() {this.subject.next();}
  getMessage(): Observable<any> {return this.subject.asObservable();}

  public url = 'https://feria.promosdag.com';
  protected restVersion = '/restv2'
  protected apiGetUrl = this.url + this.restVersion + '/get/';
  protected apiPutUrl = this.url + this.restVersion + '/put/';
  protected apiUpdateUrl = this.url + this.restVersion + '/update/';
  protected apiDeleteUrl = this.url + this.restVersion + '/delete/';
  protected apiToken = '?token=7f5b34c3816cf722cfa3e1889a62f0a1';
  public calls_to_wait = 0;
  public toast;
  public loading;
  public loading_count = 0;
  public first_loading = 0;
  public alert;
  public updates = 0;
  public show_qr = false;
  private token;

  public app_version = '';

  private prevent_fast_favorite = false;

  public config_loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public app_config: any = {
    'is_template': true,
    'logo_para_fondos_blancos': '',
    'logo_para_fondos_de_color': '',
    'countdown_pretext': 'AVIS Solo quedan:',
    'countdown_posttext': 'Maratón de precios megachollos en vehículos de ocasion:',
    'countdown_posttext_small': 'Ofertas solo disponibles 24 horas.',
    'enlace_politica_privacidad': '',
    'enlace_terminos_y_condiciones': '',
    'telefono_de_contacto': '',
    'validacion_de_dni_activa': true,
    'enlaces_legales': {},
    'onboard': [],
    'link_faq': '/section/faq',
    '_traducciones': {},
    '_otros_contenidos': [],
    '__evento_ha_comenzado': false
  };

  data: Observable<any>;
  rest: any;
  days: any = [
    {num: 'Domingo', name: 'Dom.', id: 0},
    {num: 'Lunes', name: 'Lun.', id: 1},
    {num: 'Martes', name: 'Mar.', id: 2},
    {num: 'Miércoles', name: 'Mié.', id: 3},
    {num: 'Jueves', name: 'Jue.', id: 4},
    {num: 'Viernes', name: 'Vie.', id: 5},
    {num: 'Sábado', name: 'Sáb.', id: 6},
  ];

  decode(item) {return atob(item);}
  encode(item) {return btoa(item);}

  imgSrc(item, index, pos, fallback = '/assets/imgs/logo-white.png') {
    if(item[index] && item[index][pos] && item[index][pos]['urlPath'])
      return this.rest.url + item[index][pos]['urlPath'];
    return fallback;
  }

  async getSyncTime() {
    return this.post(this.url + '/api/sync-time.php', {iso_datetime: new Date().toISOString()}).then(
      (response) => {
        if(typeof response['timediff'] !== 'undefined') {
          this.timediff = parseInt(response['timediff'])?parseInt(response['timediff']):0;
        } else {
          console.info('Hubo un error',response);
        }
      }
    );
  }

  async getAppConfig() {
    await this.getSyncTime();
    return this.post(this.url + '/api/cms/?action=multiSearch', {
      'app': {},
      'textos_generales': {},
      'otros_contenidos': {},
      'islas': {}
    }).then(
      (response) => {
        if(response['success'] == true) {
          this.app_config = response['data']['app'][1][0];
          this.app_config['_otros_contenidos'] = response['data']['otros_contenidos'][1];
          this.app_config['_islas'] = response['data']['islas'][1];
          this.app_config['_traducciones'] = {};
          for(let texto of response['data']['textos_generales'][1]) {
            this.app_config['_traducciones'][texto.identificador] = texto.texto;
          }
          try {
            let enlaces_legales_obj = JSON.parse(this.app_config.enlaces_legales);
            let enlaces_legales = {};
            for(let index in Object.keys(enlaces_legales_obj)) {
              enlaces_legales[enlaces_legales_obj[index].field_name] = enlaces_legales_obj[index].link;
            }
            this.app_config.enlaces_legales = enlaces_legales;
          } catch(e) {
            console.error('Error al cargar enlaces legales', e);
          }
          this.updates++;
          this.config_loaded.next(true);
        } else {
          console.info('Hubo un error',response);
        }
      }
    );
  }

  getLogoFondoBlanco() {
    if(this.config_loaded.value) {
      let logo = this.url + this.app_config.logo_para_fondos_blancos[0].urlPath;
      return logo;
    }
  }

  getLogoFondoDeColor() {
    if(this.config_loaded.value) {
      let logo = this.url + this.app_config.logo_para_fondos_a_color[0].urlPath;
      return logo;
    }
  }

  isFavorite(num) {
    let favorites = this.load('user_favorites');
    if(favorites)
      return !!favorites[num];
    return false;
  }

  async toggleFavorite(num) {
    if(this.prevent_fast_favorite) return Promise.resolve(false);
    this.prevent_fast_favorite = true;
    let user_token = this.load('user_token');
    if(user_token) {
      let favorites = this.load('user_favorites');
      if(!!favorites[num]) {
        await this.delete(this.url + '/api/users/?id='+favorites[num]+'&action=removeFavorite&__user_token='+user_token).then(
          (response) => {
            if(response['success'] === true) {
              this.updateUserData().then((response) => this.prevent_fast_favorite = false);
            } else {
              this.showToast('Hubo un error eliminando favorito');
            }
          }
        )
      } else {
        await this.post(this.url + '/api/users/?action=addFavorite',{vehiculo: num, __user_token: user_token}).then(
          (response) => {
            if(response['success'] === true) {
              this.updateUserData().then((response) => this.prevent_fast_favorite = false);
            } else {
              this.showToast('Hubo un error añadiendo favorito');
            }
          }
        )
      }
    } else {
      this.showToast(this.t('Inicie sesión para guardar sus vehículos favoritos.'));
    }
    this.prevent_fast_favorite = false;
  }

  async updateUserData() {
    await this.getAppConfig();
    return this.post(this.url + '/api/users?action=getUserData', {
      __user_token: this.load('user_token'),
    }).then(
      (response) => {
        if(response['success'] === true) {
          this.save('user_token', response['data']['token']);
          if(response['data']['user_data']['favoritos']
            && response['data']['user_data']['favoritos'][1]) {
            let favoritos = {};
            for(let favorito of response['data']['user_data']['favoritos'][1]) {
              favoritos[favorito.vehiculo] = favorito.num;
            }
            this.save('user_favorites', favoritos);
          }
          if(response['data']['user_data']['reservas']
            && response['data']['user_data']['reservas'][1]) {
            let reservas = {};
            for(let reserva of response['data']['user_data']['reservas'][1]) {
              reservas[reserva.vehiculo] = {
                num: reserva.num,
                createdDate: reserva.createdDate,
                identificador: reserva.identificador
              };
            }
            this.save('user_bookings', reservas);
          }
          if(response['data']['user_data'])
            this.save('user_data', response['data']['user_data']);
        }
      }
    )
  }

  async showLoading(show) {
    if(show) this.loading = true;
    else this.loading = false;
    return Promise.resolve(true);

    // Temp fix
    if(show) {
      this.loading_count++;
      if(this.loading_count === 1) {
        this.loading = await this.loadingController.create({
          message: 'Un momento...',
          backdropDismiss: true,
        });
        return await this.loading.present();
      } else {
        return Promise.resolve(true);
      }
    }
    this.loading_count--;
    if(this.loading.dismiss && this.loading_count == 0) {
        await this.loading.dismiss();
        delete this.loading;
      return Promise.resolve(false);
    } else {
      return Promise.resolve(false);
    }
  }

  async showToast(message,duration = 2000) {
    let messages = {
      SERVER_ERROR: 'Hubo un error, inténtelo de nuevo.',
      CONNECTION_ERROR: 'Hubo un error, compruebe su conexión.',
    }
    message = messages[message]?messages[message]:message;
    if(typeof this.toast !== 'undefined')
      await this.toast.dismiss();
    this.toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: 'top',
      showCloseButton: true,
      closeButtonText: "OK"
    });
    await this.toast.present();
  }

  async showAlert(
    header,
    message,
    data = {
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: (data) => {}
        },
        {
          text: 'Aceptar',
          handler: (data) => {}
        }
      ]
    }
    ) {
    let protoAlert = {
      header: header,
      message: message,
      buttons: data.buttons
    };
    let alert = await this.alertController.create(protoAlert);
    await alert.present();
  }

  showPrompt(title, message, callback, onCancel, item) {
    // let alert = this.alert.create({
    //   title: title,
    //   inputs: [
    //     {
    //       name: 'email',
    //       placeholder: 'Correo'
    //     }
    //   ],
    //   buttons: [
    //     {
    //       text: 'Cancelar',
    //       role: 'cancel',
    //       handler: onCancel
    //     },
    //     {
    //       text: message,
    //       handler: callback
    //     }
    //   ]
    // });
    // alert.present().catch();
  }

  // httpPost(tableName, json): Observable<Object> {
  //   json.from_api = true;
  //   return this.http.post(this.url + tableName + this.apiToken, json);
  // }

  // get(tableName, json): Observable<Object> {
  //   json.app = true;
  //   return this.http.post(this.apiGetUrl + tableName + this.apiToken, json);
  // }

  // put(tableName, json): Observable<Object> {
  //   json.app = true;
  //   return this.http.post(this.apiPutUrl + tableName + this.apiToken, json);
  // }

  // update(tableName, json): Observable<Object> {
  //   json.app = true;
  //   return this.http.post(this.apiUpdateUrl + tableName + this.apiToken, json);
  // }

  // delete(tableName, json): Observable<Object> {
  //   json.app = true;
  //   return this.http.post(this.apiDeleteUrl + tableName + this.apiToken, json);
  // }

  getRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.token
      })
    };
  }

  async auth() {
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Login cHJvbW9zZGFnOkRBR3BybzIwMTc='
      })
    };

    let postData = {};

    await this.http.get(this.url + '/api/auth/', requestOptions).toPromise().then(
      (response) => {
        if(response['success']) {
          this.token = response['data'].token;
        }
      }
    );
  }

  async shouldReplay(response) {
    if(response.error === 'Token expired') {
      await this.auth();
      return Promise.resolve(true);
    }
    if(response.renewToken) {
      this.token = response.renewToken;
    }
    return Promise.resolve(false);
  }

  async get(url, stop = false): Promise<Object> {
    await this.showLoading(true);
    if(!this.token)
      await this.auth();
    return this.http.get(url, this.getRequestOptions()).toPromise().then(
      async (response) => {
        if(stop) {
          await this.showLoading(false);
          return Promise.resolve(response);
        }
        return this.shouldReplay(response).then(
          async (shouldReplay) => {
            if(shouldReplay)
              return Promise.resolve(this.get(url, true));
            else {
              await this.showLoading(false);
              return Promise.resolve(response);
            }
          }
        );
      }
    );
  }

  async post(url, data, stop = false): Promise<Object> {
    await this.showLoading(true);
    if(!this.token)
      await this.auth();
    return this.http.post(url, data, this.getRequestOptions()).toPromise().then(
      async (response) => {
        if(stop) {
          await this.showLoading(false);
          return Promise.resolve(response);
        }
        return this.shouldReplay(response).then(
          async (shouldReplay) => {
            if(shouldReplay)
              return Promise.resolve(this.post(url, data, true));
            else {
              await this.showLoading(false);
              return Promise.resolve(response);
            }
          }
        );
      }
    );
  }

  async delete(url, stop = false): Promise<Object> {
    await this.showLoading(true);
    if(!this.token)
      await this.auth();
    return this.http.delete(url, this.getRequestOptions()).toPromise().then(
      async (response) => {
        if(stop) {
          await this.showLoading(false);
          return Promise.resolve(response);
        }
        return this.shouldReplay(response).then(
          async (shouldReplay) => {
            if(shouldReplay)
              return Promise.resolve(this.delete(url, true));
            else {
              await this.showLoading(false);
              return Promise.resolve(response);
            }
          }
        );
      }
    );
  }

  initOneSignal() {
    this.oneSignal.setLocationShared(false);
    this.oneSignal.startInit('7136ce6f-196a-42d9-bfa7-8bb74af7d4ad', '698258054329');
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    this.oneSignal.handleNotificationReceived().subscribe((jsonData) => {
     // do something when notification is received
      // console.log(jsonData, jsonData['payload'], jsonData['payload']['additionalData'], jsonData['payload']['additionalData']['go_to_vehicle']);
      // if(jsonData && jsonData['payload'] && jsonData['payload']['additionalData'] && jsonData['payload']['additionalData']['go_to_vehicle'])
      //   this.router.navigate(['/booking/'+jsonData['payload']['additionalData']['go_to_vehicle']]);
    });
    this.oneSignal.handleNotificationOpened().subscribe((jsonData) => {
      // do something when a notification is opened
      // console.log(jsonData, jsonData['payload'], jsonData['payload']['additionalData'], jsonData['payload']['additionalData']['go_to_vehicle']);
      // if(jsonData && jsonData['payload'] && jsonData['payload']['additionalData'] && jsonData['payload']['additionalData']['go_to_vehicle']){
      //   this.router.navigate(['/booking/'+jsonData['payload']['additionalData']['go_to_vehicle']]);
      // }
    });
    this.oneSignal.endInit();
    this.oneSignal.getIds().then(identity => {
      if(identity && identity.userId !== '')
        this.save('one_signal_id', identity.userId);
    });
  }

  sendTagsToOneSignal(key: any = '', value: any = '') {
    if(key === '' && value === '') { console.error('Clave y valor no especificados.'); return; }
    let tags = {};
    tags[key] = value;
    this.oneSignal.sendTags(tags);
  }

  load(name) {
    let item;
    if(item = window.localStorage.getItem(name))
      return JSON.parse(this.decode(item));
    return false;
  }

  save(name, item) {
    window.localStorage.setItem(name,this.encode(JSON.stringify(item)));
  }

  remove(name) {
    window.localStorage.removeItem(name);
  }

  parseaFecha(date, dateOrTime = 'default', days = 0) {
    if(!date) return '';
    let fulldate_ztime = date.split(/\+/);
    let ztime = fulldate_ztime[1];

    let fulldate_milliseconds = fulldate_ztime[0].split(/\./);
    let milliseconds = fulldate_milliseconds[1];
    milliseconds = !isNaN(parseInt(milliseconds))?parseInt(milliseconds).toString():'00';

    let fulldate = fulldate_milliseconds[0].split(/[- :TZ]/);

    date = new Date(
      fulldate[0], //Year
      fulldate[1]-1, //Month
      fulldate[2], //Day
      fulldate[3]?fulldate[3]:'12', //Hours
      fulldate[4]?fulldate[4]:'00', //Minutes
      fulldate[5]?fulldate[5]:'00', //Seconds
      milliseconds
      );

    date = date.addDays(days);
    dateOrTime = dateOrTime?dateOrTime:'default';
    date = {
      default: date,
      iso: date.toISOString(),
      date: ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear(),
      time: ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2),
      datetime: ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + ' ' + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2),
      db_like: date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2),
      only_day: date.getDay(),
      only_dayname: this.days[date.getDay()].num,
      timestamp: date.getTime(),
    };
    return date[dateOrTime];
  }

  parseaPrecio(precio, beauty = false, minDecimales = 2, maxDecimales = 2) {
    let number: any = parseFloat(precio);
    if (isNaN(number))
      return '';
    // if(beauty) number = number.toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if(beauty) number = number.toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: minDecimales, maximumFractionDigits: maxDecimales });
    return number;
  }

  // Traducciones
  t(text) {
    let slug = this.slugify(text);

    if(this.app_config._traducciones[slug]) {
      return this.app_config._traducciones[slug];
    }

    if(this.updates > 0) {
      // Enviamos a crear la traducción
      this.post(this.url + '/api/textos', {
        clave: slug,
        valor: text
      }).then(
        (response) => {
          if(response['success'] == true) {
            // Do nothing.
          } else {
            console.error('Problema al crear textos generales');
          }
        }
      );
    }

    return text;
  }

  firebaseLogEvent(event, screenName) {
    if(this.platform.is('cordova')) {
      // this.firebaseAnalytics.logEvent(event, {screenName: screenName})
      // .then((res: any) => console.log(res))
      // .catch((error: any) => console.error(error));
      console.error("Instalar plugin","Evento lanzado: '" + event + "' -> '" + screenName + "'");
    } else {
      console.log("Evento lanzado: '" + event + "' -> '" + screenName + "'");
    }
  }

  openExternal(web) {
    window.open(web, '_system', 'location=yes');
  }

  getImageSrc(item, field, position = 0, default_image = '/importador/fotos/default-vehicle.png') {
    if(item && item[field] && item[field][position] && item[field][position]['urlPath'])
      return this.url + item[field][position]['urlPath'];
    else {
      return this.url + default_image;
      // let random_image = [
      //   '/importador/fotos/E3502JGV.png',
      //   '/importador/fotos/E3647JGV.png',
      //   '/importador/fotos/E3853JKL.png',
      //   '/importador/fotos/E8098JGJ.png',
      //   '/importador/fotos/E3504JGV.png',
      //   '/importador/fotos/E3669JGV.png',
      //   '/importador/fotos/E3865JKL.png',
      //   '/importador/fotos/E8143JGJ.png',
      //   '/importador/fotos/E3505JGV.png',
      //   '/importador/fotos/E3672JGV.png',
      //   '/importador/fotos/E6698JJN.png',
      //   '/importador/fotos/E8150JGJ.png',
      //   '/importador/fotos/E3636JRB.PNG',
      //   '/importador/fotos/E3688JRB.PNG',
      //   '/importador/fotos/E6854JJN.png',
      //   '/importador/fotos/E8342JRK.png'
      // ];
      // let num = 1;
      // if(item['num']) {
      //   num = item['num'];
      // }
      // return this.url + random_image[Math.floor(num % random_image.length)];
    }
  }

  slugify(string) {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz______'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
      .replace(/\s+/g, '_') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '_and_') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\_\_+/g, '_') // Replace multiple - with single -
      .replace(/^_+/, '') // Trim - from start of text
      .replace(/_+$/, '') // Trim - from end of text
      .toUpperCase()
  }

  validateDNI(dni) {
    if(!dni) return false;
    if(!this.app_config.validacion_de_dni_activa) return true;

    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var str = dni.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    var nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    var letter = str.substr(-1);
    var charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) return true;

    return false;
  }
}