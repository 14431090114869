import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'signin', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'user-area', loadChildren: './user-area/user-area.module#UserAreaPageModule' },
  { path: 'section/:other_content_enlace', loadChildren: './section/section.module#SectionPageModule' },
  // { path: 'update', loadChildren: './update/update.module#UpdatePageModule' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    FormsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
