import { Pipe, PipeTransform } from '@angular/core';
import { CocoRESTService } from '../coco-rest.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private rest: CocoRESTService) {}

  transform(value: any, ...args: any[]): any {
    return this.rest.t(value);
  }

}
