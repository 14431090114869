import { Component } from '@angular/core';

import { MenuController, Platform, Config } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

import { CocoRESTService } from './coco-rest.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public dissable_update_button = false;
  public message_update = '';

  constructor(
    public rest: CocoRESTService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private config: Config,
    private deploy: Deploy
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if(this.platform.is('cordova')) {
        this.statusBar.hide();
        this.splashScreen.hide();
        this.rest.initOneSignal();
      }
      this.config.set('backButtonText', this.rest.t('Atrás'));
      this.load();
    });
    this.platform.resume.subscribe(() => {
      this.rest.getSyncTime();
      this.checkForUpdate();
    });
  }

  async load() {
    await this.rest.getAppConfig();
    this.checkForUpdate();
  }

  async checkForUpdate() {
    return false;
    const update = await this.deploy.checkForUpdate()
    if (update.available){
      this.rest.router.navigate(['/update'], {replaceUrl: true});
    }
  }

  async performManualUpdate() {
    return false;
    if(this.dissable_update_button) {
      this.rest.showToast(this.rest.t('Comprobación en progeso, un momento'));
    } else {
      this.dissable_update_button = true;
      this.rest.showToast(this.rest.t('Comprobando actualización'));
      if(this.platform.is('cordova')) {
        const update = await this.deploy.checkForUpdate()
        if (update.available){
          this.rest.showToast(this.rest.t('Hay una actualización disponible, descargando...'));
          await this.deploy.downloadUpdate((progress) => {
            this.message_update = 'Descargando... ('+progress+'%)';
          })
          await this.deploy.extractUpdate((progress) => {
            this.message_update = 'Extrayendo... ('+progress+'%)';
          })
          this.message_update = 'Se va a reiniciar la aplicación para aplicar la descarga.';
          await this.deploy.reloadApp();
          this.dissable_update_button = false;
          return;
        }
        this.dissable_update_button = false;
        this.rest.showToast(this.rest.t('No hay actualizaciones disponibles.'));
      } else {
        this.rest.showToast(this.rest.t('No puede buscar actualizaciones por este medio. Lo sentimos.'));
        return;
      }
    }
  }

}
