import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
// import { AppVersion } from '@ionic-native/app-version/ngx';
// import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

import { PipesModule } from './0-custom-pipes/pipes.module';
import { CocoRESTService } from './coco-rest.service';

import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

// import { mdTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/md.transition.js';
// import { Animation } from '@ionic/core';

// export function transitionAnimation(foo: Animation, el: HTMLElement, opts: any): Promise<Animation> {
//   return Promise.resolve(mdTransitionAnimation(el, opts));
// }

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({
     // navAnimation: transitionAnimation
  }), AppRoutingModule, HttpClientModule, PipesModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    OneSignal,
    // AppVersion,
    // FirebaseAnalytics,
    QRScanner,
    Deploy
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
