import { Pipe, PipeTransform } from '@angular/core';
import { CocoRESTService } from '../coco-rest.service';

@Pipe({
  name: 'imgSrc'
})
export class ImgSrcPipe implements PipeTransform {

  constructor(private rest: CocoRESTService) {}

  transform(item, field, position = 0, default_image = '/importador/fotos/default-vehicle.png', ...args: any[]): any {
    return this.rest.getImageSrc(item, field, position, default_image);
  }

}

