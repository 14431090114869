import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';
import { BypassHTMLPipe } from './bypass-html.pipe';
import { ImgSrcPipe } from './img-src.pipe';


@NgModule({
  declarations: [TranslatePipe, BypassHTMLPipe, ImgSrcPipe],
  imports: [
    CommonModule
  ],
  exports: [TranslatePipe, BypassHTMLPipe, ImgSrcPipe]
})
export class PipesModule {}